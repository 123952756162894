import { ChangeEvent, useEffect, useState } from 'react';
import styleModuleClasses from '../../../../../utils/styleModuleClasses';
import Pills from '../../../Pills';
import { FontWeight, Paragraph } from '../../../Typography';
import Input from '../../Input';
import { Color, OptionsProps, SelectProps, Size } from '../interface';
import SelectItem from './Item';
import styles from './styles.module.scss';

export default function Select({
  label,
  placeholder,
  onChange,
  defaultValue,
  options,
  pill,
  disabled,
  dropdownWrapperClassname,
  size,
  activeSearch,
  helperText,
  color,
  limit,
  fullwidth,
}: SelectProps): JSX.Element {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [selectLabel, setSelectLabel] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState<OptionsProps[]>([]);

  useEffect(() => {
    let option = '';
    if (options?.length && defaultValue) {
      option = options.find((option) => option.value === defaultValue)?.label || '';
    }
    setSelectLabel(option);
  }, [options, defaultValue]);

  const handleSelect = (option: OptionsProps) => {
    onChange(option.value?.toString() ?? '');
    setShowOptions(false);
    setSelectLabel(option?.label || '');
    setSearchOptions([]);
    setSearch('');
  };

  const handleInputSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
    findByName(value);
  };

  const findByName = (value: string) => {
    const valueLower = value.toLowerCase();

    let optionsFiltered: OptionsProps[] = [];
    if (options?.length) {
      optionsFiltered = options.filter((option) => {
        const labelLower = option.label?.toLowerCase();
        if (labelLower) {
          return labelLower.includes(valueLower);
        }
        return;
      });
    }
    setSearchOptions(optionsFiltered);
  };

  return (
    <div
      className={styleModuleClasses(styles, 'dropdown-wrapper', dropdownWrapperClassname, {
        [size as Size]: !!size,
        [color as Color]: !!color,
        fullwidth: !!fullwidth,
      })}
    >
      {label && (
        <label>
          <Paragraph size={size === 'sm' ? 3 : 2} weight={FontWeight.semiBold}>
            {label}
          </Paragraph>
        </label>
      )}
      <div className={styles['wrapper-select']}>
        <input
          placeholder={placeholder}
          className={styles['select-dropdown']}
          value={selectLabel}
          readOnly
          disabled={disabled}
          onFocus={() => !disabled && setShowOptions(true)}
          {...(!!options?.find((e) => e.label == selectLabel)?.typography?.length && {
            style: { fontFamily: options?.find((e) => e.label == selectLabel)?.typography },
          })}
        />
        {!!pill?.children && <Pills {...pill} />}
        <span className="material-symbols-rounded">expand_more</span>
      </div>
      {helperText && <span className={styles['helper-text']}>{helperText}</span>}
      {showOptions && !disabled && (
        <>
          <div className={styleModuleClasses(styles, 'dropdown-select', { focus: showOptions })}>
            {activeSearch && (
              <Input
                icon={{ name: 'search' }}
                onChange={(event) => handleInputSearch(event)}
                defaultValue={search}
                fullwidth
                placeholder="Search"
              />
            )}

            <ul>
              {activeSearch && !!search ? (
                searchOptions.length > 0 ? (
                  searchOptions
                    ?.slice(0, limit !== undefined ? limit : searchOptions.length)
                    .map((option) => <SelectItem key={option.value} option={option} handleSelect={handleSelect} />)
                ) : (
                  <li>No search results found.</li>
                )
              ) : (
                options
                  ?.slice(0, limit !== undefined ? limit : options.length)
                  .map((option) => <SelectItem key={option.value} option={option} handleSelect={handleSelect} />)
              )}
            </ul>
          </div>
          <div className={styles['select-overlay']} onClick={() => setShowOptions(false)} />
        </>
      )}
    </div>
  );
}
