import { useState } from 'react';
import { Button } from '../../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Modal from '../../../../../../../componentsV2/DesignSystem/Modal';
import { Heading, Paragraph } from '../../../../../../../componentsV2/DesignSystem/Typography';
import { Color } from '../../../../../../../componentsV2/DesignSystem/Typography/interface';
import styleModuleClasses from '../../../../../../../utils/styleModuleClasses';
import styles from './styles.module.scss';
import { PopupEmbed } from '../interface';
import copyEmbedLottie from '../../../../../../../assets/lotties/copy-embed.json';
import selectBlockLottie from '../../../../../../../assets/lotties/select-block.json';
import pasteHtmlBuilder from '../../../../../../../assets/lotties/paste-html-builder.json';
import pasteHtmlCustom from '../../../../../../../assets/lotties/paste-html-custom.json';
import pasteCtaIdCustom from '../../../../../../../assets/lotties/paste-cta-id-custom.json';
import Lottie from 'lottie-react';

interface EmbedModalProps {
  embedData: PopupEmbed;
  promoteId: number;
  callbackCloseModal: () => void;
}
interface Steps {
  wordpress: Step[];
  general: Step[];
}

interface Step {
  step: number;
  title: string;
  code?: string;
  description?: string;
}

export function EmbedModal({ embedData, promoteId, callbackCloseModal }: EmbedModalProps): JSX.Element {
  const [modalTabs, setModalTabs] = useState(0);
  function renderTabs(tabIndex: number) {
    switch (tabIndex) {
      case 0:
        return <Lottie animationData={copyEmbedLottie} />;
      case 1:
        return <Lottie animationData={selectBlockLottie} />;
      case 2:
        if (embedData.platform === 'general') return <Lottie animationData={pasteHtmlCustom} />;
        return <Lottie animationData={pasteHtmlBuilder} />;
      case 3:
        return <Lottie animationData={copyEmbedLottie} />;
      case 4:
        if (embedData.platform === 'general') return <Lottie animationData={pasteCtaIdCustom} />;
        return <Lottie animationData={pasteCtaIdCustom} />;
    }
  }

  const steps: Steps = {
    wordpress: [
      { step: 0, title: 'Copy the embed code', code: embedData.code, description: embedData.code },
      {
        step: 1,
        title:
          "Open the WordPress Editor to the page where you'd like to embed your promotion. Add a block and choose the Custom HTML option.",
      },
      {
        step: 2,
        title: 'Paste the embed code and click Save.',
      },
      ...(embedData.click.enabled
        ? [
            {
              step: 3,
              title: 'Copy the ID',
              code: `cta-beeliked-button-${promoteId}`,
              description: `cta-beeliked-button-${promoteId}`,
            },
            { step: 4, title: 'Insert the ID into your HTML tag and save.' },
          ]
        : []),
    ],
    general: [
      { step: 0, title: 'Copy the embed code', code: embedData.code, description: embedData.code },
      {
        step: 2,
        title: 'Paste the embed code in your HTML editor and save.',
      },
      ...(embedData.click.enabled
        ? [
            {
              step: 3,
              title: 'Copy the ID',
              code: `cta-beeliked-button-${promoteId}`,
              description: `cta-beeliked-button-${promoteId}`,
            },
            { step: 4, title: 'Insert the ID into your HTML tag and save.' },
          ]
        : []),
    ],
  };
  return (
    <Modal
      size="xl"
      title={`${embedData.type} embed with ${
        embedData.platform.charAt(0).toLocaleUpperCase() + embedData.platform.slice(1)
      }`}
      callback={callbackCloseModal}
      customClass={styles['modal-wrapper']}
    >
      <div>
        <aside>
          {steps[embedData.platform as keyof Steps].map(({ step, title, code, description }, index) => (
            <section
              key={index}
              className={styleModuleClasses(styles, { active: modalTabs == step })}
              onClick={() => setModalTabs(step)}
            >
              <header>
                <div className={styles['step-number']}>
                  <Heading color={Color.primary} size={6}>
                    {`0${index + 1}`}
                  </Heading>
                </div>
                <Paragraph size={2}>{title}</Paragraph>
                {description && (
                  <Button
                    firstIcon={{ children: 'copy_all' }}
                    size="sm"
                    variant="secondary"
                    onClick={() => navigator.clipboard.writeText(code ?? '')}
                  >
                    Copy Code
                  </Button>
                )}
              </header>
              {description && (
                <div className={styleModuleClasses(styles, 'code-highlight', { xl: step == 0 })}>
                  <Paragraph color="gray-40" size={2}>
                    {description}
                  </Paragraph>
                </div>
              )}
            </section>
          ))}
        </aside>
        <main>{renderTabs(modalTabs)}</main>
      </div>
    </Modal>
  );
}
