import React, { useEffect, useMemo, useRef, useState } from 'react';
import getHostnameFromUrl from '../../../../../../../utils/getHostnameFromUrl';
import getProtocolFromUrl from '../../../../../../../utils/getProtocolFromUrl';
import { ShowCodeProps } from './interface';
import styles from './styles.module.scss';
import Input from '../../../../../../../componentsV2/DesignSystem/Inputs/Input';
import Switch from '../../../../../../../componentsV2/DesignSystem/Inputs/Switch';
import Select from '../../../../../../../componentsV2/DesignSystem/Inputs/Selects/Select';
import { FontWeight, Paragraph } from '../../../../../../../componentsV2/DesignSystem/Typography';
import styleModuleClasses from '../../../../../../../utils/styleModuleClasses';
import Icon from '../../../../../../../componentsV2/DesignSystem/Icon';
import Popover from '../../../../../../../componentsV2/DesignSystem/Popover';
import { ColorPicker } from '../../../../../../../componentsV2/DesignSystem/Inputs/ColorPicker';
import useFetchTypographies from '../../../../../../../graphql/queries/useFetchTypographies';

export default function ShowPopupCode({
  data,
  dataCallback,
  promote,
  micrositeUrl,
  isFullScreen,
  isPositionAllowed,
  isCtaAllowed,
}: ShowCodeProps): JSX.Element {
  const [triggerName] = useState('cta-beeliked-button');
  const [showOnScrollPopover, setShowOnScrollPopover] = useState(false);
  const [showOnScrollElementPopover, setShowOnScrollElementPopover] = useState(false);
  const [showAfterInactivityPopover, setShowAfterInactivityPopover] = useState(false);
  const onScrollRef = useRef(null);
  const onScrollElementRef = useRef(null);
  const afterInactivityRef = useRef(null);

  const url = useMemo(
    () =>
      promote.external_domain
        ? promote.external_domain
        : `${getProtocolFromUrl(micrositeUrl)}://${getHostnameFromUrl(micrositeUrl)}`,
    [micrositeUrl, promote.external_domain],
  );

  useEffect(() => {
    const setupSize = (size: string) => {
      if (isPositionAllowed) {
        switch (size) {
          case 'small':
            return 'popover-small';
          case 'medium':
            return 'popover-medium';
          case 'big':
            return 'popover-big';
        }
      } else {
        switch (size) {
          case 'small':
            return 'modal-small';
          case 'medium':
            return 'modal-medium';
          case 'big':
            return 'modal-big';
        }
      }
    };

    if (url) {
      if (promote.render === 'nextjs') {
        return dataCallback({
          ...data,
          code: `<!-- BeeLiked Microsite Code -->
<div class="blkd_microsite"  style="opacity: 0; z-index: -9" id="blkd-microsite-${promote.id}"></div>
<script>window.addEventListener("blkdIframeCtrlLoaded", function() { blkdIframeCtrl(document.getElementById('blkd-microsite-${
            promote.id
          }'), '${micrositeUrl}'); });</script>
<script src="${url}/embed/v1/script.js"></script>
<script src="https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/blkd/embeds/embeds-1.0.7.js"></script>
<script>window.addEventListener("load", () => {
loadIframe(
  ${promote.id}, 
  ${!!data.usingTrigger.enabled && !!data.usingTrigger.onPageLoad.enabled}, 
  ${!!data.usingTrigger.enabled && !!data.usingTrigger.onPageLoad.enabled ? data.usingTrigger.onPageLoad.timeout : 0}, 
  ${!!isCtaAllowed},
  ${!!data.click.enabled},
  ${!!data.usingTrigger.enabled && !!data.usingTrigger.onPageExit.enabled},
  "${data.cta.backgroundColor}",
  "${data.cta.textColor}",
  "${data.cta.value}",
  "${data.cta.typography}",
  ${Boolean(isPositionAllowed) ? `'${data.modal.position.trim().toString()}'` : "'center-center'"},
  ${!!isFullScreen ? "'fullscreen'" : `'${setupSize(data.modal.size)}'`})
})</script>
<!-- End BeeLiked Microsite Code -->`,
        });
      } else {
        return dataCallback({
          ...data,
          code: `<!-- BeeLiked Microsite Code -->
      <button style="opacity: 0; z-index: -99999; position:fixed; top: 1rem; right: 1rem; height: 2rem;width: 2rem; border:0;background: white;font-size: 2rem;line-height: 0;border-radius: 50%;box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);cursor: pointer;">x</button>
<div class="blkd_microsite" id="blkd-microsite-${promote.id}" style="opacity: 0; transition: 0.5s; z-index: -9999;position:fixed; width: calc(100% - 4rem); max-height: calc(100% - 4rem); top: 2rem; left:2rem; overflow: auto; border-radius: 1rem;">
    <div class="blkd-loader" style="padding: 10px 0px; width: 100px; margin-left:-50px; background:#333;position:absolute; left:50%; top:160px; text-align:center; border-radius:5px; opacity:0.8;">
        <img src="${url}/imgs/microsites/loader-dark.gif" alt="" style="margin-bottom: 20px; width: 64px; height: 64px;">
        <span style="color:#ddd; font-size:14px">Loading...</span>
    </div>
</div>
<script src="${url}/min/beeliked.iframeCtrl3.min.js"></script>
<script>blkdIframeCtrl(document.getElementById('blkd-microsite-${promote.id}'), '${micrositeUrl}', { loaderClass: 'blkd-loader' });</script>
<!-- End BeeLiked Microsite Code -->`,
        });
      }
    }
  }, [
    url,
    triggerName,
    data.modal.size,
    data.click.enabled,
    data.usingTrigger.enabled,
    data.usingTrigger.onPageLoad.enabled,
    data.usingTrigger.onPageLoad.timeout,
    data.usingTrigger.onPageExit.enabled,
    data.cta,
    data.url,
    data.platform,
    data.modal.position,
  ]);
  function handleTextColor(color: string) {
    dataCallback({ ...data, cta: { ...data.cta, textColor: color } });
  }
  function handleBackgroundColor(color: string) {
    dataCallback({ ...data, cta: { ...data.cta, backgroundColor: color } });
  }

  const renderSettingsSubHeading = () => {
    let settingsSubHeading = '';
    if (isFullScreen) {
      settingsSubHeading = 'Full page display to capture full attention with your promotion.';
    } else if (isCtaAllowed) {
      settingsSubHeading = 'Popover for focused attention on specific content or areas.';
    } else if (isPositionAllowed) {
      settingsSubHeading = ' Smoothly introduce your promotion from the side of the screen.';
    } else {
      settingsSubHeading = 'Popup for quick engagement without disrupting the user experience.';
    }

    return settingsSubHeading;
  };

  const { data: googleFontsData, loading } = useFetchTypographies();
  const typographyOptions = useMemo(() => {
    if (googleFontsData) {
      return googleFontsData.map((item) => {
        const link = document.createElement('link');
        link.href = `https://fonts.googleapis.com/css?family=${item.family.replace(/ /g, '+')}`;
        link.rel = 'stylesheet';

        document.head.appendChild(link);

        return {
          value: item.family,
          label: `${item.family} - Example text to show the font`,
          typography: item.family,
        };
      });
    }
  }, [googleFontsData]);

  return (
    <div key={0} className={styles['initial-step-wrapper']}>
      <Paragraph size={1} weight={FontWeight.extraBold}>
        Settings
      </Paragraph>
      <Paragraph size={2} weight={FontWeight.regular}>
        {renderSettingsSubHeading()}
      </Paragraph>
      {/* @todo implement it when we can save on database
      <Input
        label="Your URL"
        placeholder="www.beeliked.com"
        value={data.url}
        onChange={(e) =>
          dataCallback({
            ...data,
            url: e.target.value,
          })
        }
      /> */}
      <Select
        label="Embed your promotion with"
        placeholder="Select an option"
        options={[
          { label: 'General', value: 'general' },
          { label: 'Wordpress', value: 'wordpress' },
        ]}
        defaultValue={data.platform ?? ''}
        onChange={(e) => dataCallback({ ...data, platform: e as 'general' | 'wordpress' })}
      />
      <hr />
      {!isFullScreen && (
        <>
          <Select
            label="Size"
            placeholder="Select an option"
            options={[
              { label: 'Small', value: 'small' },
              { label: 'Medium', value: 'medium' },
              { label: 'Big', value: 'big' },
            ]}
            defaultValue={data.modal.size ?? ''}
            onChange={(e) => dataCallback({ ...data, modal: { ...data.modal, size: String(e) } })}
          />
          <hr />
          {isPositionAllowed && (
            <>
              <Select
                label="Position"
                placeholder="Select an option"
                options={[
                  { label: 'Top / Left', value: 'top-left' },
                  { label: 'Top / Center', value: 'top-center' },
                  { label: 'Top / Right', value: 'top-right' },
                  { label: 'Center / Left', value: 'center-left' },
                  { label: 'Center / Center', value: 'center-center' },
                  { label: 'Center / Right', value: 'center-right' },
                  { label: 'Bottom / Left', value: 'bottom-left' },
                  { label: 'Bottom / Center', value: 'bottom-center' },
                  { label: 'Bottom / Right', value: 'bottom-right' },
                ]}
                defaultValue={data.modal.position ?? ''}
                onChange={(e) => dataCallback({ ...data, modal: { ...data.modal, position: String(e) } })}
              />
              <hr />
            </>
          )}
        </>
      )}
      {!!isCtaAllowed ? (
        <>
          <Input
            label="Text Button"
            placeholder="Click to Win!"
            value={data.cta.value}
            onChange={(e) =>
              dataCallback({
                ...data,
                cta: { ...data.cta, value: e.target.value },
              })
            }
          />
          <hr />
          <div className={styles['cta-button-style']}>
            <Paragraph weight={FontWeight.semiBold} size={2}>
              Button Style
            </Paragraph>
            <ul>
              <li>
                <Paragraph weight={FontWeight.semiBold} size={3}>
                  Text Color
                </Paragraph>
                <ColorPicker color={data.cta.textColor} callback={handleTextColor} />
              </li>
              <li>
                <Paragraph weight={FontWeight.semiBold} size={3}>
                  Background Color
                </Paragraph>
                <ColorPicker color={data.cta.backgroundColor} callback={handleBackgroundColor} />
              </li>
              <li>
                <Select
                  label="Typography"
                  placeholder="Select an option"
                  fullwidth
                  options={typographyOptions}
                  defaultValue={data.cta.typography ?? ''}
                  onChange={(e) => dataCallback({ ...data, cta: { ...data.cta, typography: String(e) } })}
                  disabled={loading}
                  activeSearch
                />
              </li>
            </ul>
          </div>
          <div className={styles['preview-wrapper']}>
            <img src="https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/preview-iframe.png" alt="" />
            <button
              className={styles[data.modal.position]}
              style={{
                background: data.cta.backgroundColor,
                color: data.cta.textColor,
                fontFamily: data.cta.typography,
              }}
            >
              {data.cta.value}
            </button>
          </div>
        </>
      ) : (
        <section className={styleModuleClasses(styles, { active: !!data.click.enabled })}>
          <header>
            <Paragraph size={1} weight={FontWeight.extraBold}>
              Open by Click
            </Paragraph>
            <Switch
              checked={data.click.enabled}
              onChange={(e) =>
                dataCallback({
                  ...data,
                  click: { ...data.click, enabled: e.target.checked },
                  usingTrigger: { ...data.usingTrigger, enabled: false },
                })
              }
            />
          </header>
          <main>
            <Paragraph size={2}>
              {`Enable the "Open by click" option to trigger the popup when an image, button, or any specified area (div) is clicked.`}
            </Paragraph>
          </main>
        </section>
      )}
      <section className={styleModuleClasses(styles, { active: !!data.usingTrigger.enabled })}>
        <header>
          <Paragraph size={1} weight={FontWeight.extraBold}>
            Open Using Triggers
          </Paragraph>
          <Switch
            checked={data.usingTrigger.enabled}
            onChange={(e) =>
              dataCallback({
                ...data,
                click: { ...data.click, enabled: false },
                usingTrigger: { ...data.usingTrigger, enabled: e.target.checked },
              })
            }
          />
        </header>
        <main>
          <Paragraph size={2}>
            {`Enable the "Open using triggers" option to trigger the popup based on specific user interactions or conditions.`}
          </Paragraph>
        </main>
        <ul className={styleModuleClasses(styles, { showTriggers: !!data.usingTrigger.enabled })}>
          <li>
            <Icon color="primary">timer</Icon>
            <Paragraph size={2} weight={FontWeight.semiBold}>
              On Page Load
            </Paragraph>
            <div className={styles['input-wrapper']}>
              <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
                Within(sec)
              </Paragraph>
              <Input
                type="number"
                value={String(data.usingTrigger.onPageLoad.timeout)}
                disabled={!data.usingTrigger.onPageLoad.enabled}
                handleDecrement={() =>
                  dataCallback({
                    ...data,
                    usingTrigger: {
                      ...data.usingTrigger,
                      onPageLoad: {
                        ...data.usingTrigger.onPageLoad,
                        timeout: data.usingTrigger.onPageLoad.timeout - 1,
                      },
                    },
                  })
                }
                handleIncrement={() =>
                  dataCallback({
                    ...data,
                    usingTrigger: {
                      ...data.usingTrigger,
                      onPageLoad: {
                        ...data.usingTrigger.onPageLoad,
                        timeout: data.usingTrigger.onPageLoad.timeout + 1,
                      },
                    },
                  })
                }
                zeroDisabled
                onChange={(e) =>
                  dataCallback({
                    ...data,
                    usingTrigger: {
                      ...data.usingTrigger,
                      onPageLoad: { ...data.usingTrigger.onPageLoad, timeout: Number(e.target.value) },
                    },
                  })
                }
              />
            </div>
            <Switch
              checked={data.usingTrigger.onPageLoad.enabled}
              onChange={(e) =>
                dataCallback({
                  ...data,
                  usingTrigger: {
                    ...data.usingTrigger,
                    onPageLoad: { ...data.usingTrigger.onPageLoad, enabled: e.target.checked },
                  },
                })
              }
            />
          </li>
          <li>
            <Icon color="primary">screenshot_monitor</Icon>
            <Paragraph size={2} weight={FontWeight.semiBold}>
              On Page Exit Intent
            </Paragraph>
            <div className={styles['input-wrapper']}>
              <Paragraph size={2} weight={FontWeight.semiBold} color="gray-48">
                Function on desktop devices only.
              </Paragraph>
            </div>
            <Switch
              checked={data.usingTrigger.onPageExit.enabled}
              onChange={(e) =>
                dataCallback({
                  ...data,
                  usingTrigger: {
                    ...data.usingTrigger,
                    onPageExit: { ...data.usingTrigger.onPageExit, enabled: e.target.checked },
                  },
                })
              }
            />
          </li>
          <li
            ref={onScrollRef}
            onMouseEnter={() => setShowOnScrollPopover(true)}
            onMouseLeave={() => setShowOnScrollPopover(false)}
          >
            <Popover
              placement="top"
              color="dark"
              hiddenOverlay
              showPopover={showOnScrollPopover}
              propRef={onScrollRef}
            >{`This feature is coming soon.`}</Popover>
            <Icon color="primary">swipe_vertical</Icon>
            <Paragraph size={2} weight={FontWeight.semiBold}>
              On Scroll
            </Paragraph>
            <div className={styles['input-wrapper']}>
              <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
                Direction
              </Paragraph>
              <Select
                placeholder="Select"
                disabled={!data.usingTrigger.onScroll.enabled}
                size="md"
                onChange={(e) =>
                  dataCallback({
                    ...data,
                    usingTrigger: {
                      ...data.usingTrigger,
                      onScroll: { ...data.usingTrigger.onScroll, direction: String(e) },
                    },
                  })
                }
                options={[
                  { label: 'Up', value: 'up' },
                  { label: 'Down', value: 'down' },
                ]}
              />
            </div>
            <Switch
              disabled
              checked={data.usingTrigger.onScroll.enabled}
              onChange={(e) =>
                dataCallback({
                  ...data,
                  usingTrigger: {
                    ...data.usingTrigger,
                    onScroll: { ...data.usingTrigger.onScroll, enabled: e.target.checked },
                  },
                })
              }
            />
          </li>
          <li
            ref={onScrollElementRef}
            onMouseEnter={() => setShowOnScrollElementPopover(true)}
            onMouseLeave={() => setShowOnScrollElementPopover(false)}
          >
            <Popover
              placement="top"
              color="dark"
              hiddenOverlay
              showPopover={showOnScrollElementPopover}
              propRef={onScrollElementRef}
            >{`This feature is coming soon.`}</Popover>
            <Icon color="primary">timer</Icon>
            <Paragraph size={2} weight={FontWeight.semiBold}>
              On Scroll to Element
            </Paragraph>
            <div className={styles['input-wrapper']}>
              <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
                Selector
              </Paragraph>
              <Input
                disabled={!data.usingTrigger.onScrollToElement.enabled}
                value={data.usingTrigger.onScrollToElement.selector}
                onChange={(e) =>
                  dataCallback({
                    ...data,
                    usingTrigger: {
                      ...data.usingTrigger,
                      onScrollToElement: { ...data.usingTrigger.onScrollToElement, selector: e.target.value },
                    },
                  })
                }
              />
            </div>
            <Switch
              disabled
              checked={data.usingTrigger.onScrollToElement.enabled}
              onChange={(e) =>
                dataCallback({
                  ...data,
                  usingTrigger: {
                    ...data.usingTrigger,
                    onScrollToElement: { ...data.usingTrigger.onScrollToElement, enabled: e.target.checked },
                  },
                })
              }
            />
          </li>
          <li
            ref={afterInactivityRef}
            onMouseEnter={() => setShowAfterInactivityPopover(true)}
            onMouseLeave={() => setShowAfterInactivityPopover(false)}
          >
            <Popover
              placement="top"
              color="dark"
              hiddenOverlay
              showPopover={showAfterInactivityPopover}
              propRef={afterInactivityRef}
            >{`This feature is coming soon.`}</Popover>
            <Icon color="primary">timer</Icon>
            <Paragraph size={2} weight={FontWeight.semiBold}>
              After Inactivity
            </Paragraph>
            <div className={styles['input-wrapper']}>
              <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
                Within(sec)
              </Paragraph>
              <Input
                type="number"
                handleDecrement={() =>
                  dataCallback({
                    ...data,
                    usingTrigger: {
                      ...data.usingTrigger,
                      afterInactivity: {
                        ...data.usingTrigger.afterInactivity,
                        timeout: data.usingTrigger.afterInactivity.timeout - 1,
                      },
                    },
                  })
                }
                handleIncrement={() =>
                  dataCallback({
                    ...data,
                    usingTrigger: {
                      ...data.usingTrigger,
                      afterInactivity: {
                        ...data.usingTrigger.afterInactivity,
                        timeout: data.usingTrigger.afterInactivity.timeout + 1,
                      },
                    },
                  })
                }
                zeroDisabled
                disabled={!data.usingTrigger.afterInactivity.enabled}
                value={String(data.usingTrigger.afterInactivity.timeout)}
                onChange={(e) =>
                  dataCallback({
                    ...data,
                    usingTrigger: {
                      ...data.usingTrigger,
                      afterInactivity: { ...data.usingTrigger.afterInactivity, timeout: Number(e.target.value) },
                    },
                  })
                }
              ></Input>
            </div>
            <Switch
              disabled
              checked={data.usingTrigger.afterInactivity.enabled}
              onChange={(e) =>
                dataCallback({
                  ...data,
                  usingTrigger: {
                    ...data.usingTrigger,
                    afterInactivity: { ...data.usingTrigger.afterInactivity, enabled: Boolean(e.target.checked) },
                  },
                })
              }
            />
          </li>
        </ul>
      </section>
    </div>
  );
}
