import { TableHeaderElement } from '../../../../../../componentsV2/TableColumnsControl/interface';

export const DEFAULT_COLUMNS: TableHeaderElement[] = [
  {
    id: '9990',
    base_field: 'name',
    type: 'name',
    enabled: true,
    name: 'Name',
    sortDisabled: true,
    order: 1,
    width: '12rem',
    is_optin: false,
  },
  {
    id: '9991',
    base_field: 'email',
    type: 'text',
    enabled: true,
    name: 'Email',
    sortDisabled: true,
    order: 2,
    width: '13rem',
    is_optin: false,
  },
  {
    id: '9992',
    base_field: 'created_at',
    type: 'date',
    enabled: true,
    name: 'Created at',
    sortDisabled: false,
    order: 1,
    align: 'center',
    is_optin: false,
  },
  {
    id: '9992',
    base_field: 'invite_code',
    type: 'text',
    enabled: false,
    name: 'Invite Code',
    sortDisabled: false,
    order: 3,
    width: '8rem',
    is_optin: false,
  },
  {
    id: '9993',
    base_field: 'promotions_name',
    type: 'tag',
    enabled: true,
    name: 'Promotions',
    sortDisabled: true,
    order: 2,
    width: '15rem',
    is_optin: false,
    align: 'center',
  },
  {
    id: '9992',
    base_field: 'email',
    type: 'text',
    enabled: true,
    name: 'Email',
    sortDisabled: true,
    order: 3,
    width: '13rem',
    is_optin: false,
  },
  {
    id: '9993',
    base_field: 'total_entries',
    type: 'text',
    enabled: true,
    name: 'Entries',
    sortDisabled: true,
    order: 4,
    align: 'center',
    is_optin: false,
  },
  {
    id: '9994',
    base_field: 'created_at',
    type: 'date',
    enabled: true,
    name: 'Date',
    sortDisabled: true,
    order: 5,
    width: '8rem',
    is_optin: false,
    align: 'center',
  },
  {
    id: '9998',
    name: 'Terms and Conditions',
    base_field: 'terms',
    type: 'pill',
    enabled: true,
    sortDisabled: false,
    order: 6,
    is_uid_field: false,
    width: '10rem',
    align: 'center',
    is_optin: false,
  },
  {
    id: '9999',
    name: 'Newsletter',
    base_field: 'newsletter',
    type: 'pill',
    enabled: true,
    sortDisabled: false,
    order: 7,
    is_uid_field: false,
    width: '10rem',
    align: 'center',
    is_optin: false,
  },
];
