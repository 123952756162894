import { PromotionVisibility } from '../../../../ts';
import InviteLinks from './Private/InviteLinks';
import KindInviteLinks from './Private/KindInviteLinks';
import SendEmailSection from './Private/SendEmailSection';
import EmbedPage from './Public/EmbedPage';
import PrintQRCode from './Public/PrintQRCode';
import ShareLink from './Public/ShareLink';
import useShareLinks from './ShareLinksProvider';

export default function VisibilityContent(): JSX.Element {
  const { promotionSelected } = useShareLinks();

  if (!promotionSelected) {
    return <></>;
  }

  return promotionSelected.visibility === PromotionVisibility.PUBLIC ? (
    <>
      <ShareLink />
      <EmbedPage />
      <PrintQRCode />
    </>
  ) : (
    <>
      <KindInviteLinks />
      <InviteLinks />
      <SendEmailSection />
    </>
  );
}
