import React, { useEffect, useMemo } from 'react';
import getHostnameFromUrl from '../../../../../../../utils/getHostnameFromUrl';
import getProtocolFromUrl from '../../../../../../../utils/getProtocolFromUrl';
import { ShowCodeProps } from './interface';
import styles from './styles.module.scss';
import { FontWeight, Paragraph } from '../../../../../../../componentsV2/DesignSystem/Typography';
import Input from '../../../../../../../componentsV2/DesignSystem/Inputs/Input';
import Select from '../../../../../../../componentsV2/DesignSystem/Inputs/Selects/Select';

export default function ShowCode({ data, dataCallback, promote, micrositeUrl }: ShowCodeProps): JSX.Element {
  const url = useMemo(
    () =>
      promote.external_domain
        ? promote.external_domain
        : `${getProtocolFromUrl(micrositeUrl)}://${getHostnameFromUrl(micrositeUrl)}`,
    [micrositeUrl, promote.external_domain],
  );

  useEffect(() => {
    if (url) {
      if (promote.render === 'nextjs') {
        return dataCallback({
          ...data,
          code: `<!-- BeeLiked Microsite Code -->
<div class="blkd_microsite" id="blkd-microsite-${
            promote.id
          }" style="position:relative;width: 100%; margin: 0 auto; max-width:${
            data.standard.width + data.standard.widthUnit
          };${
            data.standard.heightType == 'fixed'
              ? `max-height: ${data.standard.height + data.standard.heightUnit}`
              : 'height: auto;'
          }; overflow-y: auto;"></div>
<script>window.addEventListener("blkdIframeCtrlLoaded", function() { blkdIframeCtrl(document.getElementById('blkd-microsite-${
            promote.id
          }'), '${micrositeUrl}'); });</script>
<script src="${url}/embed/v1/script.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.6/iframeResizer.min.js"></script>
<script>window.addEventListener("load", () => {iFrameResize({log: false}, "#blkd-microsite-${
            promote.id
          }-iframe")})</script>
<!-- End BeeLiked Microsite Code -->`,
        });
      } else {
        return dataCallback({
          ...data,
          code: `<!-- BeeLiked Microsite Code -->
<div class="blkd_microsite" id="blkd-microsite-${promote.id}" style="position:relative;width:${
            data.standard.width + data.standard.widthUnit
          };height:${data.standard.heightType == 'fixed' ? data.standard.height + data.standard.heightUnit : 'auto'};">
    <div class="blkd-loader" style="padding: 10px 0px; width: 100px; margin-left:-50px; background:#333;position:absolute; left:50%; top:160px; text-align:center; border-radius:5px; opacity:0.8;">
        <img src="${url}/imgs/microsites/loader-dark.gif" alt="" style="margin-bottom: 20px; width: 64px; height: 64px;">
        <span style="color:#ddd; font-size:14px">Loading...</span>
    </div>
</div>
<script src="${url}/min/beeliked.iframeCtrl3.min.js"></script>
<script>blkdIframeCtrl(document.getElementById('blkd-microsite-${
            promote.id
          }'), '${micrositeUrl}', { loaderClass: 'blkd-loader' });</script>
<!-- End BeeLiked Microsite Code -->`,
        });
      }
    }
  }, [
    url,
    data.standard.height,
    data.standard.heightType,
    data.standard.heightUnit,
    data.standard.width,
    data.standard.widthUnit,
  ]);

  return (
    <div key={0} className={styles['initial-step-wrapper']}>
      <Paragraph size={1} weight={FontWeight.extraBold}>
        Settings
      </Paragraph>
      <Paragraph size={2} weight={FontWeight.regular}>
        Standard embed for seamless integration within your page content.
      </Paragraph>
      {/*@todo when implement backend to save on database <Input
        label="Your URL"
        placeholder="www.beeliked.com"
        value={data.url}
        onChange={(e) =>
          dataCallback({
            ...data,
            url: e.target.value,
          })
        }
      /> */}
      <Select
        label="Embed your promotion with"
        placeholder="Select an option"
        options={[
          { label: 'General', value: 'general' },
          { label: 'Wordpress', value: 'wordpress' },
        ]}
        defaultValue={data.platform ?? ''}
        onChange={(e) => dataCallback({ ...data, platform: e as 'general' | 'wordpress' })}
      />
      <hr />
      <div className={styles['input-wrapper']}>
        <Input
          type="number"
          label="Width"
          placeholder="1920"
          fullwidth
          zeroDisabled
          value={String(data.standard.width)}
          handleDecrement={() =>
            dataCallback({ ...data, standard: { ...data.standard, width: data.standard.width - 1 } })
          }
          handleIncrement={() =>
            dataCallback({ ...data, standard: { ...data.standard, width: data.standard.width + 1 } })
          }
          onChange={(e) => dataCallback({ ...data, standard: { ...data.standard, width: Number(e.target.value) } })}
        />
        <Select
          placeholder="Select an option"
          options={[
            { label: 'Px', value: 'px' },
            { label: '%', value: '%' },
          ]}
          defaultValue={data.standard.widthUnit}
          onChange={(e) => dataCallback({ ...data, standard: { ...data.standard, widthUnit: String(e) } })}
        />
      </div>
      <hr />
      <Select
        label="Height"
        placeholder="Select an option"
        options={[
          { label: 'Fixed', value: 'fixed' },
          { label: 'Auto', value: 'auto' },
        ]}
        defaultValue={data.standard.heightType}
        onChange={(e) => dataCallback({ ...data, standard: { ...data.standard, heightType: String(e) } })}
      />
      {data.standard.heightType == 'fixed' && (
        <div className={styles['input-wrapper']}>
          <Input
            type="number"
            placeholder="1000"
            fullwidth
            zeroDisabled
            value={String(data.standard.height)}
            handleDecrement={() =>
              dataCallback({ ...data, standard: { ...data.standard, height: data.standard.height - 1 } })
            }
            handleIncrement={() =>
              dataCallback({ ...data, standard: { ...data.standard, height: data.standard.height + 1 } })
            }
            onChange={(e) => dataCallback({ ...data, standard: { ...data.standard, height: Number(e.target.value) } })}
          />
          <Select
            placeholder="Select an option"
            options={[
              { label: 'Px', value: 'px' },
              { label: '%', value: '%' },
            ]}
            defaultValue={data.standard.heightUnit}
            onChange={(e) => dataCallback({ ...data, standard: { ...data.standard, heightUnit: String(e) } })}
          />
        </div>
      )}
    </div>
  );
}
