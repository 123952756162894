import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../../components/Pagination/paginator.gql';

export const CONTACT_VIEW_MORE = gql`
  query contactViewMore($accountId: ID!, $memberId: ID!) {
    contactViewMore(accountId: $accountId, memberId: $memberId) {
      member_id
      first_name
      last_name
      email
      phone
      company
      created_at
      promotions {
        promotion_id
        data {
          title
          entries
          outcome {
            outcome_title
            segment_label
            segment_id
          }
        }
      }
    }
  }
`;

export const CONTACTS = gql`
  query contacts($accountId: ID!, $pagination: PaginationInput, $filters: AudienceFilters) {
    contacts(accountId: $accountId, pagination: $pagination, filters: $filters) {
      data {
        name
        first_name
        last_name
        created_at
        interactions
        member_id
        promo_ids
        promotions
        terms
        newsletter
        total_entries
        base_data
        fields
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const CONTACTS_FIELDS = gql`
  query contactsFields($accountId: ID!) {
    contactsFields(accountId: $accountId) {
      data {
        id
        name
        base_field
        slug
        is_uid_field
        type
        sortDisabled
        width
        enabled
        order
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const DELETE_CONTACT = gql`
  mutation deleteContact($accountId: ID!, $memberId: ID!) {
    deleteContact(accountId: $accountId, memberId: $memberId) {
      status
      message
    }
  }
`;
