import { useState } from 'react';
import { ColorPickerProps } from './interface';
import styles from './styles.module.scss';
import { SketchPicker } from 'react-color';
import Overlay from '../../Overlay';

export function ColorPicker({ color, callback }: ColorPickerProps): JSX.Element {
  const [showTextColorPicker, setShowTextColorPicker] = useState(false);
  const [colorValue, setColorValue] = useState(color);
  return (
    <>
      <div className={styles['color-wrapper']}>
        <button style={{ background: color }} onClick={() => setShowTextColorPicker(!showTextColorPicker)}></button>
        {showTextColorPicker && (
          <SketchPicker
            color={colorValue}
            onChangeComplete={(e: { hex: string }) => {
              callback(e.hex);
            }}
            onChange={(e: { hex: string }) => {
              setColorValue(e.hex);
            }}
          />
        )}
      </div>
      {showTextColorPicker && <Overlay color="rgba(0,0,0,0)" callback={() => setShowTextColorPicker(false)} />}
    </>
  );
}
