import { useState } from 'react';
import SectionCollapsibleFull from '../../../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import { StatusBadge } from '../../../../../../componentsV2/DesignSystem/Sections/interface';
import { Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import ShowStandardCode from '../../../../Campaign/Content/Share/PublicMode/ShowCode';
import useShareLinks from '../../ShareLinksProvider';
import styles from './styles.module.scss';
import ShowPopupCode from '../../../../Campaign/Content/Share/PublicMode/ShowPopupCode';
import Drawer from '../../../../../../componentsV2/DesignSystem/Drawer';
import { PopupEmbed } from './interface';
import { EmbedModal } from './EmbedModal';

export default function EmbedPage(): JSX.Element {
  const [showStandardCode, setShowStandardCode] = useState<boolean>(false);
  const [showFullPageCode, setShowFullPageCode] = useState<boolean>(false);
  const [showPopupCode, setShowPopupCode] = useState<boolean>(false);
  const [showSliderCode, setShowSliderCode] = useState<boolean>(false);
  const [showPopoverCode, setShowPopoverCode] = useState<boolean>(false);
  const [showCode, setShowCode] = useState(false);
  const initEmbed: PopupEmbed = {
    type: '',
    code: '',
    url: '',
    platform: 'general',
    cta: { value: 'Click to Win!', backgroundColor: '#2396fb', textColor: '#ffffff', typography: 'Arial' },
    standard: {
      width: 800,
      widthUnit: 'px',
      height: 400,
      heightType: 'auto',
      heightUnit: 'px',
    },
    modal: {
      size: 'small',
      position: 'bottom-right',
    },
    click: { enabled: false },
    usingTrigger: {
      enabled: false,
      onPageLoad: { enabled: false, timeout: 1 },
      onPageExit: { enabled: false },
      onScroll: { enabled: false, direction: '' },
      onScrollToElement: { enabled: false, selector: '' },
      afterInactivity: { enabled: false, timeout: 1 },
    },
  };
  const [embedData, setEmebedData] = useState<PopupEmbed>(initEmbed);
  const { promotionSelected } = useShareLinks();
  const validateEmbedBaseData = !embedData.platform.length;
  const validateEmbedTriggers =
    !embedData.click.enabled &&
    !embedData.usingTrigger.onPageLoad.enabled &&
    !embedData.usingTrigger.onPageExit.enabled;
  const validateEmbedStandard =
    !embedData.standard.width ||
    !embedData.standard.widthUnit ||
    !embedData.standard.heightType ||
    (embedData.standard.heightType == 'fixed' ? !embedData.standard.height || !embedData.standard.heightUnit : false);

  const toggleStandardCode = () => {
    setShowStandardCode((showCode) => !showCode), setShowCode(false), setEmebedData(initEmbed);
  };
  const toggleFullpageCode = () => {
    setShowFullPageCode((showCode) => !showCode), setShowCode(false), setEmebedData(initEmbed);
  };
  const togglePopupCode = () => {
    setShowPopupCode((showCode) => !showCode), setShowCode(false), setEmebedData(initEmbed);
  };
  const toggleSliderCode = () => {
    setShowSliderCode((showCode) => !showCode), setShowCode(false), setEmebedData(initEmbed);
  };
  const togglePopoverCode = () => {
    setShowPopoverCode((showCode) => !showCode), setShowCode(false), setEmebedData(initEmbed);
  };
  const embedOptions = [
    {
      img: {
        src: 'https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/standard-iframe.png',
        alt: 'Our logo image',
        width: 250,
      },
      text: 'Standard',
      callback: toggleStandardCode,
    },
    {
      img: {
        src: 'https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/fullpage-iframe.png',
        alt: 'Our logo image',
        width: 250,
      },
      text: 'Full Page',
      callback: toggleFullpageCode,
    },
    {
      img: {
        src: 'https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/modal-iframe.png',
        alt: 'Our logo image',
        width: 250,
      },
      text: 'Popup',
      callback: togglePopupCode,
    },
    {
      img: {
        src: 'https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/slider-iframe.png',
        alt: 'Our logo image',
        width: 250,
      },
      text: 'Slider',
      callback: toggleSliderCode,
    },
    {
      img: {
        src: 'https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/popover-iframe.png',
        alt: 'Our logo image',
        width: 250,
      },
      text: 'Popover',
      callback: togglePopoverCode,
    },
  ];
  return (
    <>
      <SectionCollapsibleFull
        badge={{
          status: StatusBadge.icon,
          icon: { name: 'add_to_queue', color: 'enabled' },
          number: 2,
        }}
        expanded={true}
        header={{ title: 'Embed Page' }}
        containerClassname={styles['container-section']}
      >
        <>
          <Paragraph size={2}>Choose Embed options to add your promotion to your website</Paragraph>
          <div className={styles['embed-list']}>
            {embedOptions.map((option, index) => (
              <div className={styles['card']} key={index} onClick={option.callback}>
                <img {...option.img} />
                <Paragraph size={3}>{option.text}</Paragraph>
              </div>
            ))}
          </div>
          <Drawer
            icon={{ children: 'add_to_queue' }}
            title="Standard Embed"
            showDrawer={showStandardCode}
            callback={toggleStandardCode}
            footer={[
              { children: 'Back', variant: 'secondary', onClick: toggleStandardCode },
              {
                children: 'View Code',
                disabled: validateEmbedBaseData || validateEmbedStandard,
                variant: 'primary',
                onClick: () => {
                  setShowCode(true), setEmebedData({ ...embedData, type: 'Standard' });
                },
              },
            ]}
          >
            {showStandardCode ? (
              <ShowStandardCode
                data={embedData}
                dataCallback={setEmebedData}
                promote={{
                  id: promotionSelected.id,
                  external_domain: promotionSelected.externalDomain,
                  render: promotionSelected.render,
                }}
                micrositeUrl={promotionSelected.micrositeUrl}
              />
            ) : (
              <></>
            )}
          </Drawer>
          <Drawer
            icon={{ children: 'add_to_queue' }}
            title="Full-page Embed"
            showDrawer={showFullPageCode}
            callback={toggleFullpageCode}
            footer={[
              { children: 'Back', variant: 'secondary', onClick: toggleFullpageCode },
              {
                children: 'View Code',
                disabled: validateEmbedBaseData || validateEmbedTriggers,
                variant: 'primary',
                onClick: () => {
                  setShowCode(true), setEmebedData({ ...embedData, type: 'Full-page' });
                },
              },
            ]}
          >
            {showFullPageCode ? (
              <ShowPopupCode
                data={embedData}
                dataCallback={setEmebedData}
                promote={{
                  id: promotionSelected.id,
                  external_domain: promotionSelected.externalDomain,
                  render: promotionSelected.render,
                }}
                isFullScreen
                micrositeUrl={promotionSelected.micrositeUrl}
              />
            ) : (
              <></>
            )}
          </Drawer>
          <Drawer
            icon={{ children: 'add_to_queue' }}
            title="Popup Embed"
            showDrawer={showPopupCode}
            callback={togglePopupCode}
            footer={[
              { children: 'Back', variant: 'secondary', onClick: togglePopupCode },
              {
                children: 'View Code',
                disabled: validateEmbedBaseData || !embedData.modal.size || validateEmbedTriggers,
                variant: 'primary',
                onClick: () => {
                  setShowCode(true), setEmebedData({ ...embedData, type: 'Popup' });
                },
              },
            ]}
          >
            {showPopupCode ? (
              <ShowPopupCode
                data={embedData}
                dataCallback={setEmebedData}
                promote={{
                  id: promotionSelected.id,
                  external_domain: promotionSelected.externalDomain,
                  render: promotionSelected.render,
                }}
                micrositeUrl={promotionSelected.micrositeUrl}
              />
            ) : (
              <></>
            )}
          </Drawer>
          <Drawer
            icon={{ children: 'add_to_queue' }}
            title="Slider Embed"
            showDrawer={showSliderCode}
            callback={toggleSliderCode}
            footer={[
              { children: 'Back', variant: 'secondary', onClick: toggleSliderCode },
              {
                children: 'View Code',
                disabled:
                  validateEmbedBaseData || !embedData.modal.position || !embedData.modal.size || validateEmbedTriggers,
                variant: 'primary',
                onClick: () => {
                  setShowCode(true), setEmebedData({ ...embedData, type: 'Slider' });
                },
              },
            ]}
          >
            {showSliderCode ? (
              <ShowPopupCode
                data={embedData}
                dataCallback={setEmebedData}
                promote={{
                  id: promotionSelected.id,
                  external_domain: promotionSelected.externalDomain,
                  render: promotionSelected.render,
                }}
                isPositionAllowed
                micrositeUrl={promotionSelected.micrositeUrl}
              />
            ) : (
              <></>
            )}
          </Drawer>
          <Drawer
            icon={{ children: 'add_to_queue' }}
            title="Popover Embed"
            showDrawer={showPopoverCode}
            callback={togglePopoverCode}
            footer={[
              { children: 'Back', variant: 'secondary', onClick: togglePopoverCode },
              {
                children: 'View Code',
                disabled: validateEmbedBaseData || !embedData.modal.position || !embedData.modal.size,
                variant: 'primary',
                onClick: () => {
                  setShowCode(true), setEmebedData({ ...embedData, type: 'Popover' });
                },
              },
            ]}
          >
            {showPopoverCode ? (
              <ShowPopupCode
                data={embedData}
                dataCallback={setEmebedData}
                promote={{
                  id: promotionSelected.id,
                  external_domain: promotionSelected.externalDomain,
                  render: promotionSelected.render,
                }}
                isPositionAllowed
                isCtaAllowed
                micrositeUrl={promotionSelected.micrositeUrl}
              />
            ) : (
              <></>
            )}
          </Drawer>
        </>
      </SectionCollapsibleFull>
      {showCode && (
        <EmbedModal
          promoteId={promotionSelected.id}
          embedData={embedData}
          callbackCloseModal={() => setShowCode(false)}
        />
      )}
    </>
  );
}
